import withRoot from "../../utils/withRoot";
import React from "react";
import { Link, graphql, withPrefix } from "gatsby";
import Typography from "@material-ui/core/Typography";
import SEO from "../../components/SEO";
import Page from "../../components/Page";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import { Rocket } from "mdi-material-ui";
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // flexWrap: 'wrap',
    '& > *': {
      padding:theme.spacing(2),
      marginTop: theme.spacing(2),
      // width: theme.spacing(128),
      // height: theme.spacing(64),
    },
  },
}));

const Team = props => {
  const teams = props.data.allMarkdownRemark.edges;
  const classes = useStyles();
  return (
    <Page title="关于我们-英语启蒙">
      <SEO title="小鱼儿 小鱼妈 亲子英语 启蒙英语" />

      <video
        controls="controls"
        width="100%"
        src="http://files.kids-abc.cn/welcome.mp4"
      >
        您的浏览器不支持此视频，请更换浏览器。
      </video>
      <div className={classes.root}>
      <Paper elevation={3} >
      <Typography variant="h5">终于等到你啦！</Typography>
      <br></br>
      <Typography paragraph>
        终于等到你啦！ 我是小鱼妈，在英语启蒙的过程中，你是否也有下面两点疑问：
        <p></p>
        1. 总是担心自己英语不够好，不敢跟孩子说英语 
        <p></p>
        2.总是纠结于选哪一首歌，读哪一本书，迟迟不能开启英语启蒙
      </Typography>
      <Typography paragraph>
        如果你也有这两个问题，那么你就来对地方了!
        <p></p>
        《小鱼儿亲子英语》致力于分享最系统的亲子口语和纯干货的启蒙方法，为各位父母提供全方位的亲子口语启蒙服务。
      </Typography>
      <Typography paragraph>
        我们的交流群每周一到周五早上分享亲子口语，每周一到周三晚分享启蒙理论及方法
        加群入伙，扫描二维码添加小鱼妈个人微信（微信号：xiaoyumama03），发送“入群”即可。
        <img src="https://files.kids-abc.cn/xiaoyuma.jpeg"></img>
      </Typography>
      </Paper>
      {/* <Typography variant="h3">公众号使用方法</Typography>
      <Typography paragraph>

      </Typography> */}
      <Paper>
      <Typography variant="h5">资源获取</Typography>
      <br></br>
      <Typography paragraph>
      亲，点击这篇文章<a href="https://mp.weixin.qq.com/s/cHmKK7Sass5wqtXvlPvVVQ">《“挽起袖子”、“打点肥皂”用英语怎么说？盘点教宝宝洗手的10句地道口语！（附思维导图）》</a>分享到朋友圈
      <p></p>
      不分组、不秒删，保留两小时后截图给小鱼妈个人微信（新朋友请加微信号：xiaoyumama03）
      <p></p>
      白天工作加带娃，每天晚上10点以后统一回复消息，感谢耐心等待呦[爱心]`
      </Typography>
      </Paper>
      <Paper>
      <Typography variant="h5">启蒙试听课程</Typography>
      <br></br>
      <Typography paragraph><a href="https://appcx9lmddl1264.h5.xiaoeknow.com/v1/course/column/p_5f64c7dce4b0d59c87b6d784?type=3">启蒙试听课程</a></Typography>
      </Paper>
      <Paper>
      <Typography variant="h5">免费打卡</Typography>
      <br></br>
      <Typography paragraph>【近期上线】<p></p>和众多优秀妈妈、爸爸一起坚持学习，做学习型父母</Typography>
      </Paper>
      </div>
      {/* <GridList cellHeight={500} cols={3}>
        {teams.map(edge => {
          const {
            node: {
              frontmatter: {
                path,
                title,
                imageurl,
                jobtitle,
              },
            },
          } = edge;
          return (
            <Link key={path} to={path}>
              <GridListTile cols={1}>
                <img alt={title} src={imageurl} />
                <GridListTileBar
                  actionIcon={
                    <IconButton
                      className="teamIcon"
                      style={{ color: "rgba(255, 255, 255, 0.54)" }}
                    >
                      <Rocket />
                    </IconButton>
                  }
                  subtitle={jobtitle}
                  title={title}
                />
              </GridListTile>
            </Link>
          );
        })}
      </GridList>
     */}
    </Page>
  );
};

export const query = graphql`
  query TeamQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/posts/team/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          html
          frontmatter {
            imageurl
            title
            path
            jobtitle
          }
        }
      }
    }
  }
`;

export default withRoot(Team);
